html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  /*-moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;*/
}
body {
  padding: 0px;
  font-family: 'Inter', sans-serif;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0;
  font-size: 16px;
}
*:focus {
  outline: none;
}

/* Scrollbar magic */
* {  
  scrollbar-width: thin;
}

div.scrollable {
  scrollbar-gutter: stable both-edges;
}

a {
  text-decoration: None;
}
.svg-transition-enter {
  opacity: 0.01 !important;
}

.svg-transition-enter-active {
  opacity: 1 !important;
  transition: all 500ms ease-in;
}

.svg-transition-exit {
  opacity: 1 !important;
}

.svg-transition-exit-active {
  opacity: 0.01 !important;
  transition: all 500ms ease-in;
}
.paneltitle {
  display: inline-block !important;
  padding-bottom: 0px;
  border-bottom: 3px solid #55bf16;
}
#root {
  width: 100%;
}
.App {
  width: 100%;
  height: 100%;
  margin: 0;
}
.actionable:hover {
  cursor: pointer;
}

.midContent {
  flex-grow: 20;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
}

.flexitem- .svgCircleNode:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  background-color: '#F5F5F5';

  cursor: pointer;
}

.svgInteractable:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  cursor: pointer;
}
